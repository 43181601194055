import {
  Builder,
} from '@builder.io/sdk';

import {requirejs, define as requirejsDefine} from '@digiforce-builder/requirejs';
import loader from '@monaco-editor/loader';

export class DigiforceBuilder extends Builder {

  static settings = {
    unpkgUrl: "https://pkg.digiforce.cloud"
  }

  // importMap for requirejs
  static importMap = {}

  static packages : any = {}

  static stores = {}

  static loader = loader

  static requirejs = requirejs

  static define = requirejsDefine

  static require(params: any, callback?: Function) : any {
    if (!Builder.isBrowser) {
      console.warn('Builder.require used on the server - this should only be used in the browser');
      return;
    }
    return DigiforceBuilder.requirejs(params, callback);
  }

  // Register remote to requirejs, so you can do require('@digiforce-builder/sdk') in browser.
  // params: {'@digiforce-builder/sdk': 'https://pkg.digiforce.cloud/@digiforce-builder/sdk/dist/index.umd.js'}
  static registerImportMap( importMap:any ) : any {
    if (Builder.isBrowser) {

      Object.assign(this.importMap, importMap);
      const message = {
        type: 'builder.importMapChange',
        data: this.importMap,
      };
      parent.postMessage(message, '*');

      return DigiforceBuilder.requirejs.config({
        paths: this.importMap
      });
    }
  }

  static requirejsPromise = async(name: any): Promise<Array<any>>=>{
    return await new Promise((resolve, reject) => {
      try {
        DigiforceBuilder.requirejs(name, (...args: any)=>{
          resolve(args)
        });
      } catch (error) {
        reject(error);
      }
    })
  }

  static registerRemoteAssets = async (assetUrls: Array<string>) => {
  
    if (!Builder.isBrowser) {
      console.warn('Builder.require used on the server - this should only be used in the browser');
      return;
    }

    if(typeof assetUrls == 'string'){
      assetUrls = [assetUrls];
    }
    
    for await (const assetUrl of assetUrls) {
      var url = assetUrl.replace("https://pkg.digiforce.cloud", DigiforceBuilder.settings.unpkgUrl);
      // const assetsArray: Array<any> = await digiforceBuilder.requirejsPromise([url]);
      // for await (const assets of assetsArray) {
      //   await digiforceBuilder.registerAssets(assets, callback);
      // }

      const res = await fetch(url)
      const assets : any = await res.json()

      await DigiforceBuilder.registerAssets(assets);
    }
    Builder.register('remote-assets', {assetUrls})
  };
  
  static registerAssets = async ( assets: any) => {
    const {packages, components} = assets;

    if (!Builder.isBrowser) {
      console.warn('Builder.require used on the server - this should only be used in the browser');
      return;
    }
  
    packages?.forEach(async (pkg: any, index: number) => {
      // console.log(pkg, index);
      if (pkg.urls && Array.isArray(pkg.urls) && pkg.urls.length > 0) {
        if (pkg.package && pkg.library) {
          var url: string = pkg.urls[0];
          var cssUrl: string = pkg.urls[1];
          if (url?.endsWith(".js")) {
            url = url.replace("https://pkg.digiforce.cloud", DigiforceBuilder.settings.unpkgUrl);

            if (typeof window[pkg.library] === "undefined")
              await DigiforceBuilder.injectScript(url, { async: false, defer: true });
            DigiforceBuilder.packages[pkg.package] = pkg;
          }
          if (cssUrl?.endsWith(".css")) {
            cssUrl = cssUrl.replace("https://pkg.digiforce.cloud", DigiforceBuilder.settings.unpkgUrl);
            DigiforceBuilder.injectCSS(cssUrl)
          }
        }
      }
    });

    if(components){
      for await (const comp of components) {
        if (comp.url) {
          const compUrl = comp.url.replace("https://pkg.digiforce.cloud", DigiforceBuilder.settings.unpkgUrl);
          await DigiforceBuilder.injectScript(compUrl, { async: false, defer: true });
          if (comp.exportName && typeof window[comp.exportName] !== "undefined") {
            DigiforceBuilder.registerMeta(window[comp.exportName]); 
          }
        }
      }
    }

    Builder.register('assets', {assets})

    window.postMessage(
      {
        type: 'builder.assetsLoaded',
        data: {
          success: true,
          ...assets,
        },
      },
      '*'
    );

    if (Builder.isIframe) {

      window.parent?.postMessage(
        {
          type: 'builder.assetsLoaded',
          data: {
            success: true,
            ...assets,
          },
        },
        '*'
      );
      
    }
  };
  
  static injectScript = async ( src: string, { async = false, defer = true } ) => {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.type = 'text/javascript'
      script.async = async
      script.defer = defer
      script.addEventListener('load', ()=>{
        resolve()
      });
      script.addEventListener('error', (e) => {
        reject(e.error)
      });
      document.head.appendChild(script);
    });
  }

  static registerMeta = (meta: any) => {

    // Builder.register('metas', {meta})

    if (meta.components) {
      meta.components.forEach(async (comp: any, index: number) => {
        if (comp.npm?.package && comp.npm?.exportName) {
          const library = DigiforceBuilder.packages[comp.npm.package]?.library
          if (library) {
            const pkg = (window as any)[library]
            if (pkg){
              Builder.registerComponent(
                pkg[comp.npm.exportName],
                { name: comp.componentName }
              );
              Builder.register("meta-components", comp);
            }
          }
        }
        // register component to engine
        // ...
      });
    }
  };

  static injectCSS(cssUrl:string, { insertAt = 'top' } = {}) {
    if (!cssUrl || typeof document === 'undefined') return
  
    const head = document.head || document.getElementsByTagName('head')[0]
    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = "stylesheet"
    link.href = cssUrl;

    if (insertAt === 'top') {
      if (head.firstChild) {
        head.insertBefore(link, head.firstChild)
      } else {
        head.appendChild(link)
      }
    } else {
      head.appendChild(link)
    }
  }

  get host() {
    switch (this.env) {
      case 'development':
      case 'dev':
        return 'http://localhost:5000';
      default:
        return Builder.overrideHost || 'https://console.digiforce.cloud';
    }
  }

  static initMonaco = async (config?: any) => {

    return new Promise(resolve => {

      loader.config({
        paths: {
          vs: DigiforceBuilder.settings.unpkgUrl + '/monaco-editor/min/vs',
        },
        ...config
      });
    
      loader.init()
        .then((monaco: any) => {
          // Compatible with the old version of monaco-editor's problem of writing death MonacoEnvironment
          (window as any).MonacoEnvironment = undefined;
          if (typeof (window as any).define === 'function' && (window as any).define.amd) {
            // make monaco-editor's loader work with webpack's umd loader
            // @see https://github.com/microsoft/monaco-editor/issues/2283
            delete (window as any).define.amd;
          }
    
          (window as any).monaco = monaco;

          resolve(monaco);

        });
    })
  }
  
}

