import "./scripts/init-editing";

import { builder, Builder } from "@digiforce-builder/sdk";
export { BuilderElement } from "@digiforce-builder/sdk";

Builder.isReact = true;

export { BuilderBlocks } from "@builder.io/react";
export { BuilderBlock as BuilderBlockComponent } from "@builder.io/react";
export { BuilderContent } from "@builder.io/react";
import { BuilderComponent, onChange } from "@builder.io/react";
export { BuilderStoreContext, BuilderStore } from "@builder.io/react";
export { BuilderMetaContext } from "@builder.io/react";
export { BuilderAsyncRequestsContext } from "@builder.io/react";
export { BuilderBlock } from "@builder.io/react";

export { withBuilder } from "@builder.io/react";
export { withChildren } from "@builder.io/react";
export { noWrap } from "@builder.io/react";

export { BuilderComponent as BuilderPage, onChange };
export { BuilderComponent };
export { BuilderComponent as RenderContent };

export { Text } from "@builder.io/react";
export { Dropzone } from "@builder.io/react";
export { Fragment } from "@builder.io/react";
export { Columns } from "@builder.io/react";
export { Embed } from "@builder.io/react";
export { CustomCode } from "@builder.io/react";
export { Image, getSrcSet } from "@builder.io/react";
export { Video } from "@builder.io/react";
export { Symbol } from "@builder.io/react";
export { Button } from "@builder.io/react";
export { Section } from "@builder.io/react";
export { StateProvider } from "@builder.io/react";
export { Router } from "@builder.io/react";
export { Mutation } from "@builder.io/react";

export { Form } from "@builder.io/react";
export { FormInput } from "@builder.io/react";
export { FormSubmitButton } from "@builder.io/react";
export { Label } from "@builder.io/react"; // advanced?
export { FormSelect } from "@builder.io/react"; // advanced?
export { TextArea } from "@builder.io/react";
export { Img } from "@builder.io/react";
export { RawText } from "@builder.io/react";

export { stringToFunction } from "@builder.io/react";

export { builder, Builder };

export default builder;
